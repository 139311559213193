<template>
    <div>
        <v-row>
            <v-col cols="12">
                <Editor url="/audit/convenzione/" ref="editor" :fields="fields" :filters="filters"
                        icon="mdi-handshake-outline"
                        testo-list="SAL Convenzioni"
                        :no-insert="!$auth('staff')"
                        :no-update="!$auth('staff')"
                        :no-delete="!$auth('staff')"
                        @refresh="loadChart">
                </Editor>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-text>
                        <apexchart height="400" :options="options_chart" :series="series" type="bar"></apexchart>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Editor from "@/components/Editor";
import {mapState} from "vuex";

export default {
    data: () => ({
        fields: [
            {text: 'Codice', value: 'codice'},
            {text: 'Nome', value: 'nome'},
            {text: 'Campione', value: 'campioni'},
            {text: 'Eliminati', value: 'eliminati'},
            {text: 'Sostituiti', value: 'sostituiti'},
            {text: 'Da Pianificare', value: 'da_pianificare'},
            {text: 'Pianificati', value: 'pianificati'},
            {text: 'Da Eseguire', value: 'da_eseguire'},
            {text: 'Eseguiti', value: 'eseguiti'},
            {text: 'Da validare', value: 'da_validare'},
            {text: 'Validati', value: 'validati'},
        ],
        labels: [],
        values: [],
    }),
    mounted() {
        this.loadChart();
    },
    computed: {

        ...mapState(['dataset']),

        filters() {
            return [
                {
                    field: 'id',
                    label: 'Convenzione',
                    type: 'ajax',
                    url: '/backend/autocomplete/convenzione',
                    filter: {'chiusa': 'True'}
                },
                {field: 'nome', label: 'Nome'},
                {field: 'stato_sal', label: 'Stato', options: this.stato},
                {field: 'data_da', label: 'Data da', type: 'date'},
                {field: 'data_a', label: 'Data a', type: 'date'},
            ]
        },
        tipi_attivita() {
            return this.dataset['convenzione_tipo_attivita'];
        },
        tipi() {
            return this.dataset['convenzione_tipo'];
        },
        stato() {
            return [
                {text: 'Tutte', value: 'tutte'},
                {text: 'In gestione', value: 'in_gestione'},
                {text: 'Chiuse', value: 'chiuse'},
            ]
        },
        series() {
            return [{data: this.values}];
        },
        options_chart() {
            return {
                labels: this.labels,
            };
        },
    },
    methods: {
        async loadChart() {
            const params = this.$refs.editor.getParams();
            const res = await this.$http.get('/audit/statistiche/sal', params);
            if (res.ok) {
                const data = res.result.data;
                this.labels = data.labels;
                this.values = data.values;
            }
        },
    },
    components: {
        Editor,
    },
}
</script>